<template>
  <div
    :class="[
      `${prefix}-container`,
      mode === 'block' ? `${prefix}-block` : `${prefix}-text`,
      plain ? `${prefix}-plain` : '', 
      checked ? `${prefix}-checked` : ''
    ]"
    :style="customStyle"
    @click="handleTagClick"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    // 模式 'block'块, 'text'文本
    mode: {
      type: String,
      default: 'block'
    },
    // 是否空心
    plain: {
      type: Boolean,
      default: false
    },
    // 是否选中
    checked: {
      type: Boolean,
      default: false
    },
    // 更多自定义style
    customStyle: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      prefix: 'vf-tag'
    }
  },
  methods: {
    /**
     * 点击tag
     */
    handleTagClick() {
      this.$emit('onTag')
    }
  }
}
</script>

<style lang="scss" scoped>
.vf-tag {
  &-container {
    @include font-normal($size: 14px);
    display: inline-block;
    border-radius: 9px;
    cursor: pointer;
  }
  &-block {
    background: rgba(195, 195, 205, 0.2);
    padding: 0 8px;
    line-height: 18px;
    margin-right: 10px;
  }
  &-plain {
    color: rgba($font-theme-color, 0.8);
    border: 1px solid rgba(195, 195, 205, 0.6);
    border-radius: 12px;
    background: none;
  }
  &-text {
    border: none;
    // background: rgba(255, 47, 15, 1);
  }
  // &-text.vf-tag-checked{
  //   background: rgba(255, 47, 15, 0.1)!important;
  //   margin-right: 10px;
  // }
  &-block.vf-tag-checked {
    color: rgba($font-initialize-color, 0.85) !important;
    padding: 10px;
    border-radius: 16px;
    opacity: 0.1!important;
  }
  &-plain.vf-tag-checked {
    color: rgba($theme-color, 0.85) !important;
    border-color: $theme-color !important;
    // background: rgba(255, 47, 15, 1)!important;
  }
  &-text.vf-tag-checked {
    color: $theme-color !important;
  }
}
</style>
